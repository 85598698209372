const LandingHeroSection = () => {
  return (
    <div className="w-full flex flex-col items-center justify-start max-w-[1440px] lg:pl-0 lg:box-border lg:max-w-[1200px] md:flex md:items-start md:justify-start md:pl-0 md:box-border">
      <div className="self-stretch flex flex-col items-start justify-start lg:hidden lg:self-stretch lg:w-auto lg:max-w-[1200px]">
        <img
          className="w-full relative max-w-[1440px] overflow-hidden h-[738px] shrink-0 object-cover lg:max-w-[1200px] md:hidden md:max-w-[960px] sm:hidden"
          alt=""
          src="/image-4@2x.png"
        />
      </div>
      <div className="hidden flex-col items-start justify-start lg:flex lg:w-[1200px] lg:flex-row lg:pl-0 lg:box-border md:flex md:self-stretch md:w-auto md:flex-row md:items-center md:justify-start sm:hidden sm:max-w-[410px]">
        <img
          className="relative w-[960px] h-[695.38px] object-cover lg:flex-1 lg:self-stretch lg:h-auto lg:max-w-[1200px] md:flex"
          alt=""
          src="/image-41@2x.png"
        />
      </div>
      <div className="self-stretch hidden flex-row items-start justify-start lg:hidden md:hidden md:self-stretch md:w-auto md:pl-[50px] md:box-border sm:flex sm:self-stretch sm:w-auto sm:pl-0 sm:box-border sm:max-w-[400px]">
        <div className="flex flex-col items-start justify-start lg:hidden md:flex sm:flex sm:flex-1">
          <img
            className="relative w-[420px] h-[461.94px] object-cover sm:flex"
            alt=""
            src="/image-42@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingHeroSection;
