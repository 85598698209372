const StandOut = () => {
  return (
    <div className="self-stretch bg-white flex flex-col pt-20 px-[94px] pb-7 items-center justify-center gap-[53px] text-center text-lg text-white font-inter">
      <div className="self-stretch flex flex-col items-center justify-start gap-[17px] text-53xl text-darkslateblue font-sansation-light">
        <div className="self-stretch relative leading-[68px] uppercase">
          <span>
            <span className="font-light">Stand</span>
            <b className="font-sansation text-orange-100">{` `}</b>
          </span>
          <b className="font-sansation text-orange-100">
            <span>OUT</span>
          </b>
        </div>
        <div className="self-stretch relative text-5xl font-inter text-darkslategray-200">{`Never get lumped in with everyone again `}</div>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-center">
        <div className="flex-1 flex flex-row flex-wrap items-center justify-start gap-[46px]">
          <div className="flex-1 rounded-3xs bg-white box-border overflow-hidden flex flex-col p-6 items-center justify-start gap-[25px] min-w-[280px] border-[1px] border-solid border-whitesmoke-100">
            <div className="relative w-12 h-12 text-base">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkslateblue w-12 h-12" />
              <div className="absolute top-[15px] left-[20px] font-semibold">
                1
              </div>
            </div>
            <div className="relative font-semibold text-darkslategray-300">
              Choose Your Package
            </div>
            <div className="relative text-darkslategray-100 flex items-center justify-center w-[253px]">
              Choose from our Silver, Gold and Platinum packages with custom
              card designs
            </div>
            <div className="relative w-[89px] h-[17px] text-sm text-tomato">
              <img
                className="absolute top-[5.5px] left-[84.5px] w-[5px] h-[9px]"
                alt=""
                src="/icon.svg"
              />
              <div className="absolute top-[0px] left-[0px] font-medium">
                Learn more
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-3xs bg-white box-border overflow-hidden flex flex-col py-6 px-[18px] items-center justify-start gap-[25px] min-w-[280px] border-[1px] border-solid border-whitesmoke-100">
            <div className="relative w-12 h-12 text-base">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkslateblue w-12 h-12" />
              <div className="absolute top-[15px] left-[19px] font-semibold">
                2
              </div>
            </div>
            <div className="relative font-semibold text-darkslategray-300">
              Get A Web Profile
            </div>
            <div className="relative text-darkslategray-100 flex items-center justify-center w-[264px]">
              Get a customized web profile page with all relevant details,
              branded for you
            </div>
            <div className="relative w-[89px] h-[17px] text-sm text-tomato">
              <img
                className="absolute top-[5.5px] left-[84.5px] w-[5px] h-[9px]"
                alt=""
                src="/icon1.svg"
              />
              {/* <div className="absolute top-[0px] left-[0px] font-medium">
                Learn more
              </div> */}
            </div>
          </div>
          <div className="flex-1 rounded-3xs bg-white box-border overflow-hidden flex flex-col py-6 px-[26px] items-center justify-start gap-[25px] min-w-[280px] border-[1px] border-solid border-whitesmoke-100">
            <div className="relative w-12 h-12 text-base">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkslateblue w-12 h-12" />
              <div className="absolute top-[15px] left-[19px] font-semibold">
                3
              </div>
            </div>
            <div className="relative font-semibold text-darkslategray-300">{`Get Ready to Work the Room `}</div>
            <div className="relative text-darkslategray-100 flex items-center justify-center w-[249px]">
              Share all your relevant contact details in under 10 seconds
            </div>
            <div className="relative w-[89px] h-[17px] text-sm text-tomato">
              <img
                className="absolute top-[5.5px] left-[84.5px] w-[5px] h-[9px]"
                alt=""
                src="/icon2.svg"
              />
              {/* <div className="absolute top-[0px] left-[0px] font-medium">
                Learn more
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <a
        className=" self-stretch rounded-28xl bg-darkslateblue overflow-hidden flex flex-row py-[26px] px-[61px] items-center justify-center"
        target="_blank"
        href="https://salesiq.zoho.com/signaturesupport.ls?widgetcode=siqb18201d2941b8af3aaf6cf4c218c76558db997a99f8305ee48891899f7f93f48"
      >
        <div>
          <div className=" text-white relative flex items-center justify-center w-[100px] shrink-0">
            Contact Us
          </div>
        </div>
      </a>
    </div>
  );
};

export default StandOut;
