const SayByeToCards = () => {
  return (
    <div className="w-full bg-white flex flex-row flex-wrap p-[50px] box-border items-center justify-center gap-[97px] max-w-[1440px] text-left text-lg text-darkslategray-100 font-inter sm:w-full sm:gap-[10px] sm:pl-2.5 sm:pr-2.5 sm:pb-0 sm:box-border">
      <div className="flex-1 flex flex-col items-center justify-start gap-[20px] min-w-[400px] max-w-[590px] sm:h-auto sm:pl-5 sm:box-border">
        <div className="self-stretch relative text-17xl font-sansation text-darkslategray-200 sm:self-stretch sm:w-auto">
          Say Bye to illegible cards
        </div>
        <div className="self-stretch relative whitespace-pre-wrap">
          <p className="m-0">{`No longer do your acquaintances have to strain and type out you contact information off  tiny fonts on a card. `}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            Busy crowded business cards are a put-off yet you need your contacts
            to find you where they find most convenient. Our cards and chips
            make it effortless to share and store your contact information
          </p>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
          <div className="flex flex-col items-start justify-start gap-[7px]">
            <img
              className="relative w-[26.14px] h-[21.7px]"
              alt=""
              src="/icon5.svg"
            />
            <img
              className="relative w-[26.14px] h-[21.7px]"
              alt=""
              src="/icon6.svg"
            />
            <img
              className="relative w-[26.14px] h-[21.7px]"
              alt=""
              src="/icon7.svg"
            />
            <img
              className="relative w-[26.14px] h-[21.7px]"
              alt=""
              src="/icon8.svg"
            />
          </div>
          <div className="flex-1 flex flex-row items-center justify-start">
            <div className="flex-1 relative leading-[161.52%] whitespace-pre-wrap flex items-center h-[124px] sm:flex-1 sm:self-stretch sm:h-auto">
              <span className="[line-break:anywhere]">
                <p className="m-0">SIlver package QR code cards</p>
                <p className="m-0">Gold package 3D QR code cards</p>
                <p className="m-0">Diamond package BNS adhesive chips</p>
                <p className="m-0">
                  Platinum package 3D Cards, BNS Cards and Chips
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        className="relative w-[430px] h-[377px] object-cover"
        alt=""
        src="/image2@2x.png"
      />
    </div>
  );
};

export default SayByeToCards;
