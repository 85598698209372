const AllInOne = () => {
  return (
    <div className="w-full bg-linen flex flex-row flex-wrap py-14 px-[60px] box-border items-center justify-center gap-[166px] max-w-[1440px] text-left text-lg text-darkslategray-100 font-inter lg:pl-[60px] lg:box-border sm:w-full sm:gap-[10px] sm:pl-2.5 sm:pr-0 sm:box-border sm:max-w-[420px]">
      <div className="h-[387px] flex flex-col items-start justify-center sm:w-[400px] sm:items-start sm:justify-center sm:py-5 sm:pr-5 sm:pl-[5px] sm:box-border">
        <img
          className="flex-1 relative max-h-full w-[408.75px] object-cover sm:self-stretch sm:w-auto"
          alt=""
          src="/image1@2x.png"
        />
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[14px] min-w-[400px] max-w-[560px] lg:pl-2.5 lg:box-border sm:pr-2.5 sm:box-border">
        <div className="flex flex-col items-start justify-start text-17xl text-darkslategray-200 font-sansation">
          <div className="relative flex items-center w-[193.41px]">{`All-In-One `}</div>
          <div className="relative text-lg font-inter text-orange-200 flex items-center w-[127.81px] mt-[-7px]">
            Online Profile
          </div>
        </div>
        <div className="self-stretch relative inline-block min-w-[320px]">
          Ever been to a networking event, taken away a dozen contacts and
          couldnt figure out who certain cards belong to? WIth an online
          profile, newly made contacts will be able to tell you apart easily as
          well as have access to your chosen contact mediums
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[7px] min-w-[320px]">
          <div className="relative w-[356px] h-[25px]">
            <div className="absolute top-[2px] left-[58px] flex items-center w-[298px]">
              Personal and Professional intro
            </div>
            <img
              className="absolute top-[0px] left-[0px] w-[28.28px] h-[25px]"
              alt=""
              src="/icon4.svg"
            />
          </div>
          <div className="relative w-[216.03px] h-[25px]">
            <div className="absolute top-[1px] left-[57.68px] flex items-center w-[158.35px]">
              Social Media
            </div>
            <img
              className="absolute top-[0px] left-[0px] w-[28.28px] h-[25px]"
              alt=""
              src="/icon4.svg"
            />
          </div>
          <div className="relative w-[398.13px] h-[25px]">
            <div className="absolute top-[1px] left-[57.68px] flex items-center w-[340.45px]">
              Business Information
            </div>
            <img
              className="absolute top-[0px] left-[0px] w-[28.28px] h-[25px]"
              alt=""
              src="/icon4.svg"
            />
          </div>
          <div className="relative w-[288.42px] h-[25px]">
            <div className="absolute top-[1px] left-[57.68px] flex items-center w-[230.74px]">
              Download to contact book
            </div>
            <img
              className="absolute top-[0px] left-[0px] w-[28.28px] h-[25px]"
              alt=""
              src="/icon4.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
