import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProfileNameIcon from "./ProfileNameIcon";

const TopWorkNav = ({ name, homepage, cologo }) => {
  const navigate = useNavigate();

  const onHomeContainerClick = useCallback(() => {
    navigate("/" + homepage);
  }, [navigate]);

  const onWorkContainerClick = useCallback(() => {
    navigate("/ely");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-row items-center justify-between text-left text-17xl text-black font-sacramento md:gap-[0px] sm:flex sm:h-auto sm:gap-[10px] sm:pl-2.5 sm:pr-2.5 sm:box-border sm:max-w-[360px]">
      {cologo ? (
        <div className="relative leading-[20px] flex items-center w-[260px] h-11 shrink-0 [-webkit-text-stroke:0.5px_#000]">
          <img className="" alt="" src={cologo} />
        </div>
      ) : (
        name
      )}
      <div className="rounded-mini bg-aliceblue-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row py-[5px] px-2.5 items-start justify-end border-[1px] border-solid border-black">
        <div className="flex flex-row items-center justify-end">
          <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-start justify-start relative gap-[15px]">
            <div
              className="relative rounded-3xs bg-aliceblue-200 w-[55px] h-[55px] cursor-pointer z-[0]"
              // onClick={onWorkCard1Click}
            />
            <button className="cursor-pointer [border:none] py-3.5 px-[11px] bg-[transparent] rounded-3xs [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[60px] h-[55px] flex flex-col box-border items-center justify-center z-[1] sm:hidden">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/work-icon1.svg"
              />
              <div className="relative text-xs leading-[20px] font-semibold font-raleway text-white text-left">
                Work
              </div>
            </button>
            <div
              className="my-0 mx-[!important] absolute top-[5px] left-[10px] flex flex-col items-center pl-[4px] pl-[4px] justify-start gap-[2px] cursor-pointer z-[2]"
              onClick={onHomeContainerClick}
            >
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/home-icon2.svg"
              />
              <div className="relative text-xs leading-[20px] font-semibold font-raleway text-black text-left">
                Me
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopWorkNav;
