import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import MainLandingPage from "./pages/MainLandingPage";
import ElllyePortfolio from "./pages/Ely5575/ProfilePortfolio";
import ElllyeHome from "./pages/Ely5575/ProfileHome";
import Emasan from "./pages/EmAsAn/ProfileHome";
import Beneshun from "./pages/BENE4915/ProfileHome";
import Benatwork from "./pages/BENE4915/ProfilePortfolio";

import Krymi from "./pages/HenKrym/ProfileHome";
import Krymiwork from "./pages/HenKrym/ProfilePortfolio";
import FelAm from "./pages/FelAm/ProfileHome";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/finalprofileportfolio":
        title = "";
        metaDescription = "";
        break;
      case "/finalprofilehome":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<MainLandingPage />} />
      <Route path="/elywork" element={<ElllyePortfolio />} />
      <Route path="/ely" element={<ElllyeHome />} />
      <Route path="/emasan" element={<Emasan />} />
      <Route path="/beneshun" element={<Beneshun />} />
      <Route path="/beneshunwork" element={<Benatwork />} />
      <Route path="/krymi" element={<Krymi />} />
      <Route path="/krymiwork" element={<Krymiwork />} />
      <Route path="/felix_armah" element={<FelAm />} />
    </Routes>
  );
}
export default App;
