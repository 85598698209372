import TopWorkNav from "../../components/TopWorkNav";
import ContactCard from "../../components/ContactCard";
import PortfolioBody from "../../components/PortfolioBody";

const ProfilePortfolio = () => {
  const works = [
    {
      img: "/Krymi/dw3.jpg",
      category: "Singer",
      projectName: "Dw3",
      link: "https://open.spotify.com/track/4ISf9ZEUJyhcTBC4C8NP6l?si=bc3e502364214eb0",
    },

    {
      img: "/Krymi/dede.jpg",
      category: "Music",
      projectName: "Dede",
      link: "https://open.spotify.com/track/3bWrp1mkmyKv8sUkjcHmIr?si=d1a198ebdec04698",
    },
    {
      img: "/Krymi/partygbee.jpg",
      category: "Afrobeat",
      projectName: "Party Gbee",
      link: "https://open.spotify.com/track/0B4zFeGBbQKA79vY3js9SL?si=88949dc6761540cc",
    },
    {
      img: "/Krymi/notty.jpg",
      category: "Song Writing",
      projectName: "Notyy",
      link: "https://open.spotify.com/track/1vii2XIW9sCyXixE2r3Szz?si=ca55d2ec63a54066",
    },
  ];
  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#D43726_35%)] w-full overflow-hidden flex flex-col py-10 px-2.5 box-border items-center justify-start text-left text-17xl text-black font-sacramento lg:pl-2.5 lg:pr-2.5 lg:box-border md:w-[960px] md:items-center md:justify-start  sm:box-border sm:max-w-[400px] sm:[background:linear-gradient(180deg,_#f2f5f9_7.5%,_#fbaa0c_8.5%)] ">
      <div className="w-full flex flex-col items-center justify-start gap-[186px] max-w-[1440px] sm:pl-0 sm:pr-[5px] sm:box-border">
        <TopWorkNav name="Krymi" homepage="krymi" cologo="" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[0px]">
            <ContactCard
              contactID="krymi"
              profileImage="/Krymi/kryhead.jpg"
              contactFName="KRYMI"
              contactMName=""
              contactLName=""
              contactPhone1="+2335443534"
              contactPhone2=""
              contactEmail1="krymi@gmail.comm"
              contactEmail2=""
              workAddressStreet=""
              workAddressCity=""
              workAddressStateProvince=""
              workAddressCountryRegion="Ghana"
              vcardSource="https://biznesscard.net/"
              contactLocation2="Accra, Ghana"
              contactPost="Musician | Singer | Song Writer | Composer | Performer"
              contactOrganization1="Kreami Records"
              contactOrganization2="Krymimusic"
              contactFacebook="https://web.facebook.com/KrymiMusic"
              contactInstagram="https://www.instagram.com/krymimusic/"
              contactGithub=""
              contactLinkedIn=""
              contactTwitter="https://twitter.com/KRYMIMUSIC"
              contactTelegram=""
              contactThreads="https://www.threads.net/@krymimusic"
            />
          </div>

          <PortfolioBody works={works} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePortfolio;
