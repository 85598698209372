import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const LandingNav = () => {
  const navigate = useNavigate();

  const onFrameContainerClick = useCallback(() => {
    navigate("/ely");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-row py-0 px-[5px] items-center justify-between sm:flex">
      <div className="flex flex-col items-start justify-center">
        <img
          className="relative w-[102px] h-[59px] object-cover sm:flex"
          alt=""
          src="/image-3@2x.png"
        />
      </div>
      <button
        onClick={onFrameContainerClick}
        className="cursor-pointer [border:none] p-0 bg-[transparent] h-11 flex flex-col items-end justify-center relative gap-[10px]"
      >
        <div className="relative rounded-3xs bg-orange-100 w-[108px] h-11 z-[0]" />
        <div className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[0px] text-base font-semibold font-inter text-gray-100 text-center inline-block w-[108px] h-[19px] shrink-0 z-[1]">
          About Us
        </div>
      </button>
    </div>
  );
};

export default LandingNav;
