import LandingNav from "../components/LandingNav";
import LandingHeroSection from "../components/LandingHeroSection";
import StandOut from "../components/StandOut";
import FirstImpressions from "../components/FirstImpressions";
import ShareContact from "../components/ShareContact";
import AllInOne from "../components/AllInOne";
import SayByeToCards from "../components/SayByeToCards";
import JourneyForm from "../components/JourneyForm";
import LandingPageFooter from "../components/LandingPageFooter";

const MainLandingPage = () => {
  // const navigate = useNavigate();

  // const onFrameContainerClick = useCallback(() => {
  //   navigate("/finalprofilehome");
  // }, [navigate]);

  return (
    <div className="relative bg-white w-full overflow-y-auto flex flex-col items-center justify-start text-center text-17xl text-white font-sansation lg:items-start lg:justify-start lg:pl-0 lg:pr-0 lg:box-border md:pl-0 md:box-border sm:h-auto">
      <div className="w-full overflow-hidden shrink-0 flex flex-col items-center justify-start max-w-[1440px] lg:w-full lg:max-w-[1200px] sm:max-w-[420px]">
        <LandingNav />
      </div>
      <div className="w-full flex flex-col items-center justify-start max-w-[1440px] lg:pl-0 lg:box-border lg:max-w-[1200px] md:pl-0 md:box-border md:max-w-[960px] sm:w-full sm:max-w-[410px]">
        <LandingHeroSection />
        <div className="w-full flex flex-col items-center justify-start gap-[10px] max-w-[1440px] sm:w-[420px]">
          <div className="w-full bg-orange-100 overflow-hidden flex flex-row py-[22px] px-px box-border items-start justify-center max-w-[1440px]">
            <div className="flex-1 relative">Custom Order Now</div>
          </div>
          <StandOut />
        </div>
        <FirstImpressions />
        <ShareContact />
        <AllInOne />
        <SayByeToCards />
        <div className="self-stretch flex flex-col items-center justify-start text-left text-35xl">
          <JourneyForm />
          <div className="self-stretch bg-gray-300 hidden flex-col pt-[50px] px-5 pb-0 items-center justify-start lg:hidden md:flex sm:hidden">
            <div className="self-stretch flex flex-row flex-wrap py-0 pr-0 pl-5 items-start justify-center">
              <div className="flex-1 h-[707px] flex flex-col items-start justify-center gap-[15px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[9px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch relative">{`Your journey into the future of networking begins with `}</div>
                  </div>
                  <div className="relative text-53xl leading-[68px] uppercase flex items-center w-[414px] text-lightskyblue font-sansation-light">
                    <span className="[line-break:anywhere] w-full">
                      <span>
                        <span className="font-light">One</span>
                      </span>
                      <b>
                        <span>{` `}</span>
                        <span className="text-orange-100">Step</span>
                      </b>
                    </span>
                  </div>
                </div>
                <div className="self-stretch flex flex-col p-2.5 items-start justify-start">
                  <div className="self-stretch bg-gray-500 flex flex-col py-8 px-[31px] items-start justify-start gap-[24px]">
                    <input
                      className="font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue"
                      type="text"
                      placeholder="Email"
                    />
                    <input
                      className="font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue"
                      type="text"
                      placeholder="Phone"
                    />
                    <input
                      className="font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue"
                      type="text"
                      placeholder="When would be an ideal time for you to receive a call?"
                    />
                    <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[134px] h-[54px]">
                      <div className="absolute top-[0px] left-[0px] bg-darkslateblue w-[134px] h-[54px]" />
                      <div className="absolute top-[16px] left-[30px] text-lg font-medium font-inter text-white text-center">{`Contact `}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start max-w-[1440px] sm:flex sm:w-full sm:h-auto">
          <LandingPageFooter />
        </div>
      </div>
    </div>
  );
};

export default MainLandingPage;
