import TopHomeNav from "../../components/TopHomeNav";
import ContactCard from "../../components/ContactCard";
import MainHomeBody from "../../components/MainHomeBody";

const ProfileHome = () => {
  const description = ` I am a results-oriented and visionary leader with a strong track record of driving successful projects to completion while fostering a collaborative team environment. As a Project Manager and CEO, I bring a unique blend of technical expertise, design sensibility, and a commitment to delivering exceptional solutions. My leadership encompasses roles as a Project Manager, Software Developer, Designer, Product Owner, and Customer & Stakeholder Relationship Manager. I thrive on leveraging agile methodologies and embracing continuous improvement ideologies to achieve high-quality results that make a lasting impact.`;

  const interests = [
    {
      icon: "/code-icon.svg",
      title: "Web Development",
      desc: "As a frontend developer, I enjoy building scalable practical solutions by leveraging the power and flexibility of JavaScript and ReactJS",
    },

    {
      icon: "/app.svg",
      title: "UI/UX Designing",
      desc: "I enjoy creating visually appealing and intuitive user interfaces that allow for a memorable experience. Figma and Adobe Illustrator, Photoshop and XD are my winning combo",
    },
    {
      icon: "/ux-icon.svg",
      title: "Project Management",
      desc: "With a focus on team-centric organizing and proven agile project management methodologies, I strive to make sure that all projects are well equipped and team members have all the support they need to do their best work unhindered",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Consulting",
      desc: "I take advantage of my vast experience creating businesses and leading great teams in Asia to help guide entrepreneurs and businesses that want to venture and thrive in various industried",
    },
  ];

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#0f05a5_35%)] w-full overflow-hidden flex flex-row flex-wrap py-10 px-2.5 box-border items-start justify-center lg:max-w-[1200px] md:items-center md:justify-start md:pl-2.5 md:box-border md:max-w-[960px] sm:pl-[15px] sm:pr-2.5 sm:box-border sm:max-w-[410px] sm:[background:linear-gradient(180deg,_#f2f5f9_8%,_#0f05a5_9%)]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[186px] max-w-[1440px] lg:w-full sm:flex-col sm:gap-[136px] sm:pl-0 sm:box-border">
        <TopHomeNav name="Elias Lloyd-Yemoh" workpage="elywork" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[50px]">
            <ContactCard
              contactID="ely"
              profileImage="/Elias/image-1@2x.png"
              contactFName="Elias"
              contactMName=""
              contactLName="Lloyd-Yemoh"
              contactPhone1="+233598029696"
              contactPhone2=""
              contactEmail1="elias_lloyd@live.com"
              contactEmail2="elias@altreontech.com"
              workAddressStreet="24 Wuding Lu"
              workAddressCity="Shanghai"
              workAddressStateProvince="Shanghai"
              workAddressCountryRegion="China"
              vcardSource="https://biznesscard.net/"
              contactLocation2="Accra, Ghana"
              contactPost="Certified Project Manager PMI-ACP | Founder, Altreon Technologies | CEO, TheBlock."
              contactOrganization1="Altreon Technologies"
              contactOrganization2="TheBlock App"
              companyWebsite="https://theblockapp.co/"
              contactFacebook="https://web.facebook.com/profile.php?id=100092529806652"
              contactInstagram="https://instagram.com/altreontech?igshid=NTc4MTIwNjQ2YQ=="
              contactGithub="https://github.com/elyxer"
              contactLinkedIn="https://www.linkedin.com/in/elias-lloyd-yemoh/"
            />
          </div>
          <MainHomeBody description={description} interests={interests} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
