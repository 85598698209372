import OnePagerNav from "../../components/OnePagerNav";
import ContactCard from "../../components/ContactCard";
import MainHomeBody from "../../components/MainHomeBody";

const ProfileHome = () => {
  const description = `An agile sales and marketing, business development, and digital marketing professional with (10+) years’ experience in sales organisation, implementing integrated marketing solutions, developing and implementing marketing strategies and business development strategies. Adept at managing paid advertisements on social media, managing social interventions and CSR projects for Institutions in communities based on SDGs, event management, event hosting and music marketing and management. 
  `;

  const interests = [
    {
      icon: "/code-icon.svg",
      title: "Digital Marketing",
      desc: "I enjoy curating compelling social media and website content for clients, marketing products and services of clients digitally and managing their day-to-day social media accounts and presence.",
    },

    {
      icon: "/app.svg",
      title: "Event Hosting & Management ",
      desc: "Given the platform to be part of people making beautiful memories has become one of my favourite things to do. I’m a certified public speaker and a professional events MC, Host and Compere with a having a 10+ years experience in organising and managing events.",
    },
    {
      icon: "/ux-icon.svg",
      title: "Business Development",
      desc: "I curate proposals and implement marketing campaigns, develop new ways to communicate a company’s message to existing and new clients, and work with the company to see them executed.",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Project Management",
      desc: "For the past 10 years and counting, and using team efforts, I have led numerous SDGs projects all over Ghana and made sure each project is implemented with success and team members work together to achieve our set goals.",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Consulting",
      desc: "I make use of my vast experience in marketing, sales, business developments, project management and events to help lead and structure businesses and individuals already in operation and new businesses in different industries.",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Music Management",
      desc: "I take advantage of my experience having worked for a major record label and having created a network in the entertainment and media industry, I carry out works in music distribution to digital streaming platforms, marketing, promotion, management and consulting.",
    },
  ];

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#0f05a5_35%)] w-full overflow-hidden flex flex-row flex-wrap py-10 px-2.5 box-border items-start justify-center lg:max-w-[1200px] md:items-center md:justify-start md:pl-2.5 md:box-border md:max-w-[960px] sm:pl-[15px] sm:pr-2.5 sm:box-border sm:max-w-[410px] sm:[background:linear-gradient(180deg,_#f2f5f9_7%,_#0f05a5_15%)]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[186px] max-w-[1440px] lg:w-full sm:flex-col sm:gap-[136px] sm:pl-0 sm:box-border">
        <OnePagerNav name="Emmanuel Asare Anyafo" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[50px]">
            <ContactCard
              contactID="emasan"
              profileImage="/Emma/emasare.jpg"
              contactFName="Emmanuel"
              contactMName="Asare"
              contactLName="Anyafo"
              contactPhone1="+233247241293"
              contactPhone2=""
              contactEmail1="easareanyafo@gmail.com"
              contactEmail2="mckwabenaasare@gmail.com"
              workAddressStreet="Liberia Street"
              workAddressCity="Madina"
              workAddressStateProvince="Greater Accra"
              workAddressCountryRegion="Ghana"
              vcardSource="https://masterprofile.net/"
              contactLocation2=""
              contactTitle="Certified Digital Marketer | Business Development Manager | Event Host & Event Manager | Marketing Consultant | Project Manager | Music Business Consultant"
              contactOrganization1="Master Profile"
              contactOrganization2=""
              companyWebsite=""
              contactFacebook="https://www.Facebook.com/KwabenaAsareYourMc"
              contactInstagram="https://instagram.com/kwabena_asare_your_mc?igshid=OGQ5ZDc2ODk2ZA=="
              contactGithub=""
              contactLinkedIn="https://www.linkedin.com/in/emmanuel-asare-anyafo-64010064"
            />
          </div>

          <MainHomeBody description={description} interests={interests} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
