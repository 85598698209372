import { useCallback } from "react";
import ProfileNameIcon from "./ProfileNameIcon";
import { useNavigate } from "react-router-dom";

const TopHomeNav = ({ name, workpage }) => {
  const navigate = useNavigate();

  const onWorkCard1Click = useCallback(() => {
    navigate("/" + workpage);
  }, [navigate]);

  const onWorkContainerClick = useCallback(() => {
    navigate("/" + workpage);
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-row items-center justify-between text-left text-17xl text-black font-sacramento lg:gap-[560px] md:gap-[0px] sm:min-w-[200px]">
      <div className="relative leading-[20px] flex items-center w-[260px] h-11 shrink-0 [-webkit-text-stroke:0.5px_#000]">
        {name}
      </div>
      <div className="flex flex-row py-0 px-2.5 items-center justify-end">
        <div className="rounded-mini bg-aliceblue-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row py-[5px] px-2.5 items-start justify-end border-[1px] border-solid border-black sm:gap-[-3px] sm:pl-2.5 sm:box-border">
          <div className="flex flex-row items-center justify-end gap-[15px]">
            <button className="cursor-pointer [border:none] p-0 bg-[transparent] rounded-3xs [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[60px] h-[55px] flex flex-col items-center justify-center sm:hidden">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/home-icon.svg"
              />
              <div className="relative text-xs leading-[20px] font-semibold font-raleway text-white text-left">
                ME
              </div>
            </button>
            <div className="flex flex-row items-center justify-center">
              <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-center justify-center relative gap-[10px]">
                <div
                  className="relative rounded-3xs bg-aliceblue-200 w-[60px] h-[55px] cursor-pointer z-[0]"
                  onClick={onWorkCard1Click}
                />
                <div
                  className="my-0 mx-[!important] absolute top-[5px] left-[15px] flex flex-col items-center justify-start gap-[2px] cursor-pointer z-[1]"
                  onClick={onWorkContainerClick}
                >
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/work-icon.svg"
                  />
                  <div className="relative text-xs leading-[20px] font-semibold font-raleway text-black text-left">
                    Work
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="flex flex-row items-center justify-start gap-[860px] lg:gap-[560px] md:gap-[360px] sm:w-auto sm:[align-self:unset] sm:gap-[40px] sm:pl-[50px] sm:pr-5 sm:box-border">
    //   <div className="h-[33.48px] flex flex-col items-center justify-between">
    //     <ProfileNameIcon />
    //   </div>
    //   <div className="flex flex-row py-0 px-2.5 items-center justify-end">
    //     <div className="rounded-mini bg-aliceblue-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row py-[5px] px-2.5 items-start justify-end border-[1px] border-solid border-black sm:gap-[-3px] sm:pl-2.5 sm:box-border">
    //       <div className="flex flex-row items-center justify-end gap-[15px]">
    //         <button className="cursor-pointer [border:none] p-0 bg-[transparent] rounded-3xs [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[60px] h-[55px] flex flex-col items-center justify-center sm:hidden">
    //           <img
    //             className="relative w-6 h-6 overflow-hidden shrink-0"
    //             alt=""
    //             src="/home-icon.svg"
    //           />
    //           <div className="relative text-xs leading-[20px] font-semibold font-raleway text-white text-left">
    //             Home
    //           </div>
    //         </button>
    //         <div className="flex flex-row items-center justify-center">
    //           <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-center justify-center relative gap-[10px]">
    //             <div
    //               className="relative rounded-3xs bg-aliceblue-200 w-[60px] h-[55px] cursor-pointer z-[0]"
    //               onClick={onWorkCard1Click}
    //             />
    //             <div
    //               className="my-0 mx-[!important] absolute top-[5px] left-[15px] flex flex-col items-center justify-start gap-[2px] cursor-pointer z-[1]"
    //               onClick={onWorkContainerClick}
    //             >
    //               <img
    //                 className="relative w-6 h-6 overflow-hidden shrink-0"
    //                 alt=""
    //                 src="/work-icon.svg"
    //               />
    //               <div className="relative text-xs leading-[20px] font-semibold font-raleway text-black text-left">
    //                 Work
    //               </div>
    //             </div>
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default TopHomeNav;
