import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const JourneyForm = () => {
  const navigate = useNavigate();

  const releod = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="self-stretch bg-gray-300 flex flex-col py-[50px] px-[38px] items-center justify-start text-right text-35xl text-white font-sansation md:hidden sm:flex sm:gap-[50px] sm:pt-0 sm:box-border sm:w-[420px]">
      <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[33px]">
        <img
          className="flex-1 relative max-w-full overflow-hidden h-[649.28px] min-w-[400px] sm:flex"
          alt=""
          src="/mask-group.svg"
        />
        <div className="flex-1 h-[707px] flex flex-col py-0 px-6 box-border items-start justify-center gap-[16px] min-w-[410px]">
          <div className="self-stretch flex flex-col items-end justify-start gap-[9px]">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch relative">{`Your journey into the future of networking begins with `}</div>
            </div>
            <div className="relative text-53xl leading-[68px] uppercase flex items-center w-[414px] text-lightskyblue font-sansation-light">
              <span className="[line-break:anywhere] w-full">
                <span>
                  <span className="font-light">One</span>
                </span>
                <b>
                  <span>{` `}</span>
                  <span className="text-orange-100">Step</span>
                </b>
              </span>
            </div>
          </div>
          <div className="self-stretch flex flex-col p-2.5 items-start justify-start">
            <div className="self-stretch bg-gray-500 flex flex-col py-8 px-[31px] items-start justify-start gap-[24px]">
              <input
                className=" text-gray-100 font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue"
                type="text"
                placeholder="Email"
              />
              {/* <input
                className="font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue text-gray-100"
                type="text"
                placeholder="Phone"
              /> */}
              {/* <input
                className="text-gray-100 font-medium font-inter text-lg bg-[transparent] self-stretch rounded-8xs overflow-hidden flex flex-row p-[18px] items-center justify-start border-[1px] border-solid border-lightskyblue"
                type="text"
                placeholder="When would be an ideal time for you to receive a call?"
              /> */}
              <a
                target="_blank"
                href="https://salesiq.zoho.com/signaturesupport.ls?widgetcode=siqb18201d2941b8af3aaf6cf4c218c76558db997a99f8305ee48891899f7f93f48"
              >
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[134px] h-[54px]">
                  <div className="absolute top-[0px] left-[0px] bg-darkslateblue w-[134px] h-[54px]" />
                  <div className="absolute top-[16px] left-[30px] text-lg font-medium font-inter text-white text-center">{`Let's Chat`}</div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyForm;
