import TopHomeNav from "../../components/TopHomeNav";
import ContactCard from "../../components/ContactCard";
import MainHomeBody from "../../components/MainHomeBody";

const ProfileHome = () => {
  const description = `Welcome to the world of Krymi, where melodies and lyrics intertwine to create a tapestry of emotions. As a dedicated song artist,I weave stories through music, crafting sonic landscapes that resonate with body and soul. I’ll take you on a lyrical journey into my heart and mind, and experience the power of their songs to inspire, uplift, and keep the party going.`;

  const interests = [
    {
      icon: "/code-icon.svg",
      title: "Music & Song Writing",
      desc: "  I have mastered the art of creating melodies that resonate with body and soul. Crafting harmonious compositions, whether on a piano, guitar is a testament to my love for music. With a unique blend of genres and styles, I stir upa emotions and leave a lasting impression through my music.",
    },

    {
      icon: "/app.svg",
      title: "Singer",
      desc: "Fans say my uniquely captivating voice possesses the power to transport listeners to another dimension. Whether belting out powerful anthems or delivering heart-wrenching ballads, I always aim to please and leave my audience spellbound.",
    },
    {
      icon: "/ux-icon.svg",
      title: "Live Performances",
      desc: "I'm not merely an artist but a Performer who brings their music to life on stage. My live performances are a fusion of raw energy, emotional depth, and sheer charisma.I have graced stages around the world, leaving audiences awestruck and craving more.",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Brand Marketing Icon",
      desc: "Beyond my artistry, I have also ventured into the world of brand marketing, leveraging my unique voice and creativity to build iconic brand campaigns that leave a lasting impression.",
    },
  ];
  let profileTheme = "#D43726";

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#D43726_35%)] w-full overflow-hidden flex flex-row flex-wrap py-10 px-2.5 box-border items-start justify-center lg:max-w-[1200px] md:items-center md:justify-start md:pl-2.5 md:box-border md:max-w-[960px] sm:pl-[15px] sm:pr-2.5 sm:box-border sm:max-w-[410px] sm:[background:linear-gradient(180deg,_#f2f5f9_8%,_#D43726_9%)]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[176px] max-w-[1440px] lg:w-full sm:flex-col sm:gap-[136px] sm:pl-0 sm:box-border">
        <TopHomeNav name="Krymi" workpage="krymiwork" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[50px]">
            <ContactCard
              contactID="krymi"
              profileImage="/Krymi/kryhead.jpg"
              contactFName="KRYMI"
              contactMName=""
              contactLName=""
              contactPhone1="+2335443534"
              contactPhone2=""
              contactEmail1="krymi@gmail.comm"
              contactEmail2=""
              workAddressStreet=""
              workAddressCity=""
              workAddressStateProvince=""
              workAddressCountryRegion="Ghana"
              vcardSource="https://biznesscard.net/"
              contactLocation2="Accra, Ghana"
              contactPost="Musician | Singer | Song Writer | Composer | Performer"
              contactOrganization1="Kreami Records"
              contactOrganization2="Krymimusic"
              contactFacebook="https://web.facebook.com/KrymiMusic"
              contactInstagram="https://www.instagram.com/krymimusic/"
              contactGithub=""
              contactLinkedIn=""
              contactTwitter="https://twitter.com/KRYMIMUSIC"
              contactTelegram=""
              contactThreads="https://www.threads.net/@krymimusic"
            />
          </div>
          <MainHomeBody description={description} interests={interests} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
