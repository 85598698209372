import React from "react";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
// import ReactDOM from "react-dom";
// import second from 'first'

export default function DownloadContact({
  contactID,
  profileImage,
  contactFName,
  contactLName,
  contactMName,
  linkedinTabIndex,
  contactPhone1,
  contactPhone2,
  contactEmail1,
  contactEmail2,
  workAddressStreet,
  workAddressCity,
  workAddressStateProvince,
  workAddressCountryRegion,
  contactLocation2,
  contactTitle,
  contactOrganization1,
  contactOrganization2,
  companyWebsite,
  contactFacebook,
  contactInstagram,
  contactGithub,
  contactLinkedIn,
}) {
  const downloadTxtFile = (vcfText) => {
    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = contactFName + contactLName + "Contact.vcf";
    document.body.appendChild(element);
    element.click();
  };

  const printRef = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = contactFName + "QR.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const CreateVCard = () => {
    var vCardsJS = require("vcards-js");

    //create a new vCard
    var vCard = vCardsJS();

    //set properties
    vCard.firstName = contactFName;
    vCard.middleName = contactMName;
    vCard.lastName = contactLName;
    vCard.organization = contactOrganization1;
    vCard.workPhone = contactPhone1;
    vCard.homePhone = contactPhone2;
    vCard.title = contactTitle;
    vCard.workUrl = companyWebsite;
    vCard.photo.embedFromFile = profileImage;
    vCard.email = contactEmail1;
    vCard.workEmail = contactEmail2;
    vCard.workAddress.label = "Work Address";
    vCard.workAddress.street = workAddressStreet;
    vCard.workAddress.city = workAddressCity;
    vCard.workAddress.stateProvince = workAddressStateProvince;
    vCard.workAddress.countryRegion = workAddressCountryRegion;
    vCard.source = "https://biznesscard.net/#/" + contactID;
    vCard.socialUrls["facebook"] = "";
    vCard.socialUrls["linkedIn"] = "";
    vCard.socialUrls["twitter"] = "";
    vCard.socialUrls["flickr"] = "";
    vCard.socialUrls["custom"] = "";
    vCard.note = "Added from biznesscard.net";

    //-------------------------------------------------------------------------
    return vCard.getFormattedString();
  };

  // ReactDOM.render(<QRCode value="hey" />, document.getElementById("Container"));

  return (
    <div>
      <button
        className="cursor-pointer [border:none] py-2.5 px-5 bg-[transparent] mb-10 rounded-xl [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[278px] flex flex-row box-border items-center justify-center gap-[10px]"
        onClick={() => downloadTxtFile(CreateVCard())}
      >
        <img
          className="relative w-6 h-6 overflow-hidden shrink-0"
          alt=""
          src="/materialsymbolsdownload.svg"
        />
        <div className="relative text-xs leading-[20px] font-semibold font-raleway text-aliceblue-100 text-left">
          Save to Contacts
        </div>
      </button>

      <div>
        <div ref={printRef} style={{ background: "white", padding: "16px" }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={CreateVCard()}
            viewBox={`0 0 256 256`}
          />
        </div>
        <button
          className="cursor-pointer [border:none] py-2.5 px-5 bg-[transparent]  rounded-xl [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[278px] flex flex-row box-border items-center justify-center gap-[10px]"
          onClick={handleDownloadImage}
        >
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0"
            alt=""
            src="/materialsymbolsdownload.svg"
          />
          <div className="relative text-xs leading-[20px] font-semibold font-raleway text-aliceblue-100 text-left">
            Download Offline QR Code
          </div>
        </button>
      </div>
    </div>
  );
}
