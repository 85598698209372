const PortfolioBody = ({ works }) => {
  return (
    <div className="flex-1 rounded-3xs bg-white flex flex-col py-10 px-[30px] box-border items-start justify-start gap-[15px] min-w-[360px] text-left text-21xl text-gray-400 font-poppins md:flex-1 md:h-auto md:gap-[15px] md:pl-5 md:pr-5 md:pb-5 md:box-border sm:min-w-[23px] sm:max-w-[410px] sm:flex-[unset] sm:self-stretch ">
      <div className="self-stretch flex flex-col items-start justify-start gap-[15px]">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start">
          <div className="flex-1 flex flex-row flex-wrap items-center justify-start">
            <div className="flex-1 flex flex-row flex-wrap p-2.5 box-border items-center justify-start gap-[79px] min-w-[200px]">
              <div className="flex-1 relative tracking-[0.03em] leading-[40px] font-medium inline-block min-w-[319px]">
                PORTFOLIO
              </div>
              <img
                className="flex-1 relative max-w-full overflow-hidden h-1 min-w-[120px]"
                alt=""
                src="/line-51.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row py-0 px-2.5 box-border items-center justify-center min-w-[330px] text-base text-black font-raleway">
          <div className="flex-1 relative leading-[30px] font-medium">
            My Projects.
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start text-13xl text-black md:self-stretch md:w-auto">
        <div className="self-stretch flex flex-col p-2.5 items-start justify-start gap-[10px]">
          <div className="self-stretch relative tracking-[0.03em] leading-[40px] font-medium md:self-stretch md:w-auto">
            What I’ve done!
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center text-sm font-raleway lg:gap-[27px]">
          <div className="flex-1 flex flex-row flex-wrap items-start justify-center gap-[26px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[30px] min-w-[300px] max-w-[400px]">
              {works.map((aproject, index) => {
                if (index < works.length / 2) {
                  return (
                    <a
                      href={aproject.link}
                      target="_blank"
                      className="[text-decoration:none] self-stretch rounded-3xs overflow-hidden flex flex-col items-start justify-start text-[inherit]"
                    >
                      <img
                        className="self-stretch relative max-w-full overflow-hidden h-[194px] shrink-0 object-cover"
                        alt=""
                        src={aproject.img}
                      />
                      <div className="self-stretch bg-aliceblue-100 flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                        <div className="self-stretch relative tracking-[0.03em] leading-[20px]">
                          {aproject.category}
                        </div>
                        <div className="self-stretch flex flex-row items-center justify-between text-5xl font-poppins">
                          <div className="flex-1 relative tracking-[0.03em] leading-[25px] font-semibold">
                            {aproject.projectName}
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              })}
            </div>

            <div className="flex-1 flex flex-col items-start justify-start gap-[22px] min-w-[300px] max-w-[400px]">
              {works.map((aproject, index) => {
                if (index >= works.length / 2) {
                  return (
                    <a
                      href={aproject.link}
                      target="_blank"
                      className="[text-decoration:none] self-stretch rounded-3xs overflow-hidden flex flex-col items-start justify-start text-[inherit]"
                    >
                      <a className="[text-decoration:none] self-stretch rounded-3xs overflow-hidden flex flex-col items-start justify-start text-[inherit]">
                        <img
                          className="self-stretch relative max-w-full overflow-hidden h-[194px] shrink-0 object-cover"
                          alt=""
                          src={aproject.img}
                        />
                        <div className="self-stretch bg-aliceblue-100 flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                          <div className="self-stretch relative tracking-[0.03em] leading-[20px]">
                            {aproject.category}
                          </div>
                          <div className="self-stretch flex flex-row items-center justify-between text-5xl font-poppins">
                            <div className="flex-1 relative tracking-[0.03em] leading-[25px] font-semibold">
                              {aproject.projectName}
                            </div>
                          </div>
                        </div>
                      </a>
                    </a>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioBody;
