const MainHomeBody = ({ description, interests }) => {
  return (
    <div className="flex-1 rounded-3xs bg-white flex flex-col pt-[40px] px-0 pb-0 box-border items-start justify-start gap-[15px] min-w-[360px] text-left text-21xl text-gray-400 font-poppins md:pl-[30px] md:pr-[45px] md:box-border sm:w-auto sm:[align-self:unset] sm:pl-0 sm:pr-0 sm:pb-5 sm:box-border sm:max-w-[410px]">
      <div className="self-stretch flex flex-col py-0 px-[30px] items-start justify-start gap-[15px] sm:pl-[30px] sm:pr-5 sm:box-border">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start">
          <div className="flex-1 flex flex-row items-center justify-start">
            <div className="flex-1 flex flex-row flex-wrap p-2.5 box-border items-center justify-start gap-[79px] min-w-[161px]">
              <div className="flex-1 relative tracking-[0.03em] leading-[40px] font-medium inline-block min-w-[141px]">
                ABOUT ME
              </div>
              <img
                className="flex-1 relative max-w-full overflow-hidden h-1"
                alt=""
                src="/line-5.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row py-0 px-5 box-border items-center justify-center min-w-[330px] text-base text-black font-raleway md:pl-0 md:pr-10 md:box-border">
          <div className="flex-1 relative leading-[30px] font-medium">
            {description}
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col py-5 px-[30px] items-start justify-start gap-[18px] text-13xl text-black">
        <div className="self-stretch flex flex-row p-2.5 items-center justify-start">
          <div className="relative tracking-[0.03em] leading-[40px] font-medium">
            What I do!
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[27px] text-5xl">
          <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[250px]">
            {/* -------------------------end------------------------------ */}
            {interests.map((anInterest, index) => {
              if (index < interests.length / 2) {
                return index % 2 == 0 ? (
                  <div className="self-stretch rounded-mini bg-bisque flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                    <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                      <img
                        className="relative w-6 h-6 overflow-hidden shrink-0"
                        alt=""
                        src={anInterest.icon}
                      />
                      <div className="flex-1 relative leading-[25px] font-semibold font-sansation">
                        {anInterest.title}
                      </div>
                    </div>
                    <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">
                      {anInterest.desc}
                    </div>
                  </div>
                ) : (
                  <div className="self-stretch rounded-mini bg-aliceblue-100 flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                    <div className="self-stretch flex flex-row items-center justify-between">
                      <img
                        className="relative w-6 h-6 overflow-hidden mr-2 shrink-0"
                        alt=""
                        src={anInterest.icon}
                      />
                      <div className="flex-1 relative tracking-[0.03em] leading-[25px] font-semibold font-sansation">
                        {anInterest.title}
                      </div>
                    </div>
                    <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">
                      {anInterest.desc}
                    </div>
                  </div>
                );
              }
            })}
            {/* -------------------------end------------------------------ */}

            {/* <div className="self-stretch rounded-mini bg-bisque flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/code-icon.svg"
                />
                <div className="flex-1 relative leading-[25px] font-semibold">
                  Web Development
                </div>
              </div>
              <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">
                As a frontend developer, I enjoy building scalable practical
                solutions by leveraging the power and flexibility of JavaScript
                and ReactJS
              </div>
            </div> */}
            {/* <div className="self-stretch rounded-mini bg-aliceblue-100 flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
              <div className="self-stretch flex flex-row items-center justify-between">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/ux-icon.svg"
                />
                <div className="flex-1 relative tracking-[0.03em] leading-[25px] font-semibold">
                  UI/UX Designing
                </div>
              </div>
              <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">{`I enjoy creating visually appealing and intuitive user interfaces that allow for a memorable experience. Figma and Adobe Illustrator, Photoshop and XD are my winning combo `}</div>
            </div> */}
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[200px]">
            {/* -------------------------end------------------------------ */}
            {interests.map((anInterest, index) => {
              if (index >= interests.length / 2) {
                return index % 2 == 1 ? (
                  <div className="self-stretch rounded-mini bg-bisque flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                    <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                      <img
                        className="relative w-6 h-6 overflow-hidden shrink-0 mr-2"
                        alt=""
                        src={anInterest.icon}
                      />
                      <div className="flex-1 relative leading-[25px] font-semibold font-sansation">
                        {anInterest.title}
                      </div>
                    </div>
                    <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">
                      {anInterest.desc}
                    </div>
                  </div>
                ) : (
                  <div className="self-stretch rounded-mini bg-aliceblue-100 flex flex-col py-[17px] px-[23px] box-border items-start justify-start gap-[8px] min-w-[250px]">
                    <div className="self-stretch flex flex-row items-center justify-between">
                      <img
                        className="relative w-6 h-6 overflow-hidden shrink-0 mr-2"
                        alt=""
                        src={anInterest.icon}
                      />
                      <div className="flex-1 relative tracking-[0.03em] leading-[25px] font-semibold font-sansation">
                        {anInterest.title}
                      </div>
                    </div>
                    <div className="self-stretch relative text-sm tracking-[0.03em] leading-[20px] font-raleway">
                      {anInterest.desc}
                    </div>
                  </div>
                );
              }
            })}
            {/* -------------------------end------------------------------ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHomeBody;
