import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const TopHomeNav = ({ name }) => {
  const navigate = useNavigate();

  const onWorkCard1Click = useCallback(() => {
    navigate("/elyportfolio");
  }, [navigate]);

  // const contactname=name
  return (
    <div className="self-stretch flex flex-row items-center justify-between text-left text-17xl text-black font-sacramento lg:gap-[560px] md:gap-[0px] sm:w-auto sm:[align-self:unset] sm:gap-[40px] sm:pl-[50px] sm:pr-5 sm:box-border">
      <div className="relative leading-[20px] flex items-center w-[400px] h-11 shrink-0 [-webkit-text-stroke:1px_#000]">
        {name}
      </div>
    </div>
  );
};

export default TopHomeNav;
