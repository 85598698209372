import TopHomeNav from "../../components/TopHomeNav";
import ContactCard from "../../components/ContactCard";
import MainHomeBody from "../../components/MainHomeBody";

const ProfileHome = () => {
  const description = `I am the General Overseer of Spiritlife Revival Ministries; an apostolic prophetic mission based ministry headquatered in Accra, Ghana. I have been ministering full time since 2006 with a mandate to redefine the prophetic. My messages are centered around Faith, the Prophetic, Wisdom, the Anointing and the Supernatural.`;

  const interests = [
    {
      icon: "/hands-praying.svg",
      title: "Ministry",
      desc: "As a prophet of God, I am entrusted with a divine mission to convey messages of God's guidance, wisdom, and enlightenment to humanity. Through prayer, faith, and annointing, I receive insights and revelations that offer solace, direction, and hope to those seeking divine guidance. My role involves interpreting and sharing these messages with humility and compassion, fostering understanding and unity among people from diverse backgrounds. ",
    },

    {
      icon: "/bitcoin.svg",
      title: "Cryptocurrency Trading",
      desc: "I immerse myself in the dynamic realm of digital assets, utilizing astute market analysis and strategic thinking to navigate the volatile landscape. Vigilantly monitoring blockchain technology advancements, market trends, and news, I make informed decisions to capitalize on price fluctuations. My days are spent executing trades across various cryptocurrencies, employing risk management techniques to safeguard my investments.",
    },
    {
      icon: "/forexpound.svg",
      title: "Forex Trading",
      desc: "As a forex trader, I navigate the dynamic world of global currencies, leveraging my analytical skills and the prophetic to predict market movements. I constantly monitor economic indicators, geopolitical events, and technical charts to make informed trading decisions. Through diligent research and risk management, I strategically execute trades to capitalize on fluctuations in exchange rates.",
    },
    {
      icon: "/mentor-icon.svg",
      title: "Consulting",
      desc: " As a consultant, my role centers around providing expert guidance and solutions to individuals or organizations facing complex challenges. I employ a blend of industry knowledge, data analysis, and problem-solving skills to assess situations and offer tailored recommendations. Through active listening and effective communication, I collaborate with clients to understand their goals and objectives, crafting actionable strategies that drive positive outcomes.",
    },
  ];

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#0f05a5_35%)] w-full overflow-hidden flex flex-row flex-wrap py-10 px-2.5 box-border items-start justify-center lg:max-w-[1200px] md:items-center md:justify-start md:pl-2.5 md:box-border md:max-w-[960px] sm:pl-[15px] sm:pr-2.5 sm:box-border sm:max-w-[400px] sm:[background:linear-gradient(180deg,_#f2f5f9_7%,_#0f05a5_15%)]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[186px] max-w-[1440px] lg:w-full sm:flex-col sm:gap-[136px] sm:pl-0 sm:box-border">
        <TopHomeNav name="Prophet Elbernard" workpage="beneshunwork" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-bordern sm:mt-[50px]">
            <ContactCard
              contactID="beneshun"
              title="Prophet"
              profileImage="/BENE/ben4234.jpeg"
              contactFName="Bernard"
              contactMName="Elbernard"
              contactLName="Nelson-Eshun"
              contactPhone1="+233550000096"
              contactPhone2="+233234077788"
              contactEmail1="prophetbernardwlbernard@gmail.com"
              contactEmail2=""
              workAddressStreet=""
              workAddressCity=""
              workAddressStateProvince="Greater Accra"
              workAddressCountryRegion="Ghana"
              vcardSource=""
              contactLocation2="Accra, Ghana"
              contactPost="General Overseer @ SPIRITLIFE REVIVAL MINISTRIES | Prophet | Forex Trader | Cryptopreneur | Moneypreneur"
              contactOrganization1="SPIRITLIFE REVIVAL MINISTRIES"
              contactOrganization2="TheBlock App"
              companyWebsite=""
              contactFacebook="https://web.facebook.com/bernard.nelsontv"
              contactInstagram="https://www.instagram.com/prophetbernardelbernard/"
              contactGithub=""
              contactLinkedIn=""
              contactTwitter="https://twitter.com/ProphElbernard"
              contactTelegram="https://t.co/5JZ1TODt6J"
              contactThreads="https://www.threads.net/@prophetbernardelbernard"
            />
          </div>
          <MainHomeBody description={description} interests={interests} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
