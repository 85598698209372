import DownloadContact from "./Downloadvcard";

const ContactCard = ({
  contactID,
  profileImage,
  title,
  contactFName,
  contactLName,
  contactMName,
  linkedinTabIndex,
  contactPhone1,
  contactPhone2,
  contactEmail1,
  contactEmail2,
  workAddressStreet,
  workAddressCity,
  workAddressStateProvince,
  workAddressCountryRegion,
  contactLocation2,
  contactPost,
  contactOrganization1,
  contactOrganization2,
  companyWebsite,
  contactFacebook,
  contactInstagram,
  contactGithub,
  contactLinkedIn,
  contactTwitter,
  contactTelegram,
  contactThreads,
}) => {
  return (
    <div className="self-stretch rounded-3xs bg-white flex flex-col pt-[132px] px-5 pb-[21px] items-center justify-start relative gap-[15px] text-center text-[28.42px] text-gray-400 font-raleway sm:self-stretch sm:w-auto sm:pl-2.5 sm:pr-2.5 sm:box-border">
      <img
        className="absolute my-0 mx-[!important] w-[calc(100%_-_0px)] top-[-151px] right-[103px] left-[0px] rounded-t-lgi max-w-full overflow-hidden h-[300px] shrink-0 object-cover z-[0]"
        alt=""
        src={profileImage}
      />
      <div className="self-stretch flex flex-col items-center justify-start gap-[15px] z-[1]">
        <div className="self-stretch flex flex-col items-center justify-start">
          <div className="self-stretch overflow-hidden flex flex-row mt-7 items-center justify-center">
            <b className="flex-1 relative leading-[35.52px]">
              {title} {contactFName} {contactMName} {contactLName}
            </b>
          </div>
        </div>
        <div className="self-stretch rounded-8xs overflow-hidden flex flex-row flex-wrap py-0 px-[5px] items-center justify-center text-base text-gray-600">
          <div className="flex-1 relative leading-[24.5px] font-medium">
            <p className="m-0">{contactPost}</p>
            <p className="m-0">{` `}</p>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[9.1px]">
          {contactLinkedIn ? (
            <a href={contactLinkedIn} target="_blank">
              <button
                className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-row items-center justify-center"
                tabIndex={linkedinTabIndex}
              >
                <div className="rounded-[8.27px] bg-aliceblue-100 w-[19.84px] flex flex-col py-[2.480564832687378px] px-[1.653709888458252px] box-border items-start justify-start">
                  <img
                    className="relative w-[15.76px] h-[14.88px]"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactFacebook ? (
            <a href={contactFacebook} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <img
                  className="relative w-5 h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/facebookicon.png"
                />
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactInstagram ? (
            <a href={contactInstagram} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] h-[61px] flex flex-row box-border items-center justify-center">
                <img
                  className="relative w-4 h-4 overflow-hidden shrink-0"
                  alt=""
                  src="/instaIcon.png"
                />
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactTwitter ? (
            <a href={contactTwitter} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <div className="flex flex-col p-[1.653709888458252px] items-start justify-start">
                  <img
                    className="relative w-[16.54px] h-[16.13px]"
                    alt=""
                    src="/devicon_twitter.png"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactTwitter ? (
            <a href={contactTwitter} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <div className="flex flex-col p-[1.653709888458252px] items-start justify-start">
                  <img
                    className="relative w-[16.54px] h-[16.13px]"
                    alt=""
                    src="/x-twitter.png"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactTelegram ? (
            <a href={contactTelegram} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <div className="flex flex-col p-[1.653709888458252px] items-start justify-start">
                  <img
                    className="relative w-[16.54px] h-[16.13px]"
                    alt=""
                    src="/logos_telegram.png"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactGithub ? (
            <a href={contactGithub} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <div className="flex flex-col p-[1.653709888458252px] items-start justify-start">
                  <img
                    className="relative w-[16.54px] h-[16.13px]"
                    alt=""
                    src="/vector1.svg"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
          {contactThreads ? (
            <a href={contactThreads} target="_blank">
              <button className="cursor-pointer [border:none] py-[20.67137336730957px] px-[24.805648803710938px] bg-aliceblue-100 flex-1 rounded-[8.27px] flex flex-col items-center justify-center">
                <div className="flex flex-col p-[1.653709888458252px] items-start justify-start">
                  <img
                    className="relative w-[16.54px] h-[16.13px]"
                    alt=""
                    src="/simple-icons_threads.png"
                  />
                </div>
              </button>
            </a>
          ) : (
            console.log("One skipped")
          )}
        </div>
        <div className="self-stretch rounded-mini bg-whitesmoke-200 flex flex-col py-5 px-[10%] items-start justify-start gap-[16px] text-left text-sm text-gray-700">
          <div className="self-stretch flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-start gap-[22px]">
              <div className="rounded-mini bg-aliceblue-100 flex flex-row py-2.5 px-[15px] items-start justify-start">
                <img className="relative w-3 h-5" alt="" src="/vector2.svg" />
              </div>
              <div className="flex-1 flex flex-col py-[5px] px-0 items-start justify-start">
                <div className="self-stretch h-[15px] flex flex-row items-center justify-start">
                  <div className="relative font-semibold">Phone</div>
                </div>
                <div className="self-stretch relative leading-[20px] font-semibold text-black">
                  {contactPhone1}
                  <p className="m-0">{contactPhone2}</p>
                </div>
              </div>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
            alt=""
            src="/line-6.svg"
          />
          <div className="w-[278px] flex flex-row items-center justify-center gap-[22px]">
            <div className="rounded-mini bg-aliceblue-100 w-[43px] flex flex-col p-2.5 box-border items-start justify-start">
              <img
                className="relative w-5 h-[19.36px]"
                alt=""
                src="/vector3.svg"
              />
            </div>
            <div className="flex-1 flex flex-col py-[5px] px-0 items-start justify-start">
              <div className="self-stretch h-[15px] flex flex-row items-center justify-start">
                <div className="relative font-semibold">Email</div>
              </div>
              <div className="self-stretch relative leading-[20px] font-semibold max-w-[20px] text-black">
                <p className="m-0">{contactEmail1}</p>
                <p className="m-0">{contactEmail2}</p>
              </div>
            </div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gainsboro" />
          <div className="w-[278px] flex flex-row items-center justify-center gap-[20px]">
            <div className="rounded-lg bg-aliceblue-100 w-11 h-[42px] flex flex-col p-2.5 box-border items-center justify-center">
              <img
                className="relative w-[15px] h-[21px]"
                alt=""
                src="/vector4.svg"
              />
            </div>
            <div className="flex-1 flex flex-col py-[5px] px-0 items-start justify-start">
              <div className="self-stretch h-[15px] flex flex-row items-center justify-start">
                <div className="relative font-semibold">Work Address</div>
              </div>
              <div className="self-stretch relative leading-[20px] font-semibold text-black">
                <p className="m-0">
                  {workAddressStateProvince} {workAddressCountryRegion}
                </p>
                {contactLocation2 ? (
                  <p className="m-0">{contactLocation2}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gainsboro" />
          {/* <button className="cursor-pointer [border:none] py-2.5 px-5 bg-[transparent] rounded-xl [background:linear-gradient(88.9deg,_#6518a5,_#0001a4_41.15%,_#0000f4)] w-[278px] flex flex-row box-border items-center justify-center gap-[10px]">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/materialsymbolsdownload.svg"
            />
            <div className="relative text-xs leading-[20px] font-semibold font-raleway text-aliceblue-100 text-left">
              Save to Contacts
            </div>
          </button> */}
          <DownloadContact
            contactID={contactID}
            title={title}
            profileImage={profileImage}
            contactFName={contactFName}
            contactLName={contactLName}
            contactMName={contactMName}
            linkedinTabIndex={linkedinTabIndex}
            contactPhone1={contactPhone1}
            contactPhone2={contactPhone2}
            contactEmail1={contactEmail1}
            contactEmail2={contactEmail2}
            workAddressStreet={workAddressStreet}
            workAddressCity={workAddressCity}
            workAddressStateProvince={workAddressStateProvince}
            workAddressCountryRegion={workAddressCountryRegion}
            contactLocation2={contactLocation2}
            contactPost={contactPost}
            contactOrganization1={contactOrganization1}
            contactOrganization2={contactOrganization2}
            companyWebsite={companyWebsite}
            contactFacebook={contactFacebook}
            contactInstagram={contactInstagram}
            contactGithub={contactGithub}
            contactLinkedIn={contactLinkedIn}
          />
          {/* <DownloadContact
            contactID="ely"
            profileImage="/image-1@2x.png"
            contactFName="Elias"
            contactMName=""
            contactLName="Lloyd-Yemoh"
            contactPhone1="+233598029696"
            contactPhone2=""
            contactEmail1="elias_lloyd@live.com"
            contactEmail2="elias@altreontech.com"
            workAddressStreet="Wuding Lu"
            workAddressCity="Shanghai"
            workAddressStateProvince="Shanghai"
            workAddressCountryRegion="China"
            vcardSource="https://biznesscard.net/"
            contactLocation2="Accra, Ghana"
            contactTitle="Certified Project Manager PMI-ACP | Founder, Altreon Technologies | CEO, TheBlock."
            contactOrganization1="Altreon Technologies"
            contactOrganization2="TheBlock App"
            companyWebsite="https://theblockapp.co/"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
