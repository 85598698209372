const FirstImpressions = () => {
  return (
    <div className="w-full bg-linen flex flex-col py-[100px] px-[50px] box-border items-center justify-start gap-[18px] min-w-[400px] max-w-[1440px] text-center text-21xl text-black font-sansation sm:pl-[5px] sm:pr-[5px] sm:box-border sm:min-w-360 sm:w-[420px]">
      <b className="self-stretch relative inline-block min-w-[390px]">
        FIRST IMPRESSIONS DO MATTER
      </b>
      <div className="self-stretch relative text-5xl leading-[145.52%] font-inter text-darkslategray-200 inline-block min-w-[400px]">
        Communicate confidence, sophistication, a readiness for the future and
        most of all a commitment to sustainability
      </div>
    </div>
  );
};

export default FirstImpressions;
