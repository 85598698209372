import TopWorkNav from "../../components/TopWorkNav";
import ContactCard from "../../components/ContactCard";
import PortfolioBody from "../../components/PortfolioBody";

const ProfilePortfolio = () => {
  const works = [
    {
      img: "/Elias/rectangle-22@2x.png",
      category: "Frontend Development",
      projectName: "Senturium",
      link: "#/elywork",
    },

    {
      img: "/Elias/rectangle-222@2x.png",
      category: "UI/UX Design",
      projectName: "Beta Chrome",
      link: "#/elywork",
    },
    {
      img: "/Elias/rectangle-223@2x.png",
      category: "Development",
      projectName: "Card My Profile",
      link: "#/elywork",
    },
    {
      img: "/Elias/rectangle-221@2x.png",
      category: "Consulting",
      projectName: "TheBlock",
      link: "#/elywork",
    },
  ];
  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#D43726_35%)] w-full overflow-hidden flex flex-col py-10 px-2.5 box-border items-center justify-start text-left text-17xl text-black font-sacramento lg:pl-2.5 lg:pr-2.5 lg:box-border md:w-[960px] md:items-center md:justify-start  sm:box-border sm:max-w-[400px] sm:[background:linear-gradient(180deg,_#f2f5f9_7.5%,_#fbaa0c_8.5%)]">
      <div className="w-full flex flex-col items-center justify-start gap-[186px] max-w-[1440px] sm:pl-0 sm:pr-[5px] sm:box-border">
        
        <TopWorkNav
          name="Elias Lloyd-Yemoh"
          homepage="ely"
          cologo="theLogo1.png"
        />
        
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
            <ContactCard
              contactID="ely"
              profileImage="/Elias/image-1@2x.png"
              contactFName="Elias"
              contactMName=""
              contactLName="Lloyd-Yemoh"
              contactPhone1="+233598029696"
              contactPhone2=""
              contactEmail1="elias_lloyd@live.com"
              contactEmail2="elias@altreontech.com"
              workAddressStreet="24 Wuding Lu"
              workAddressCity="Shanghai"
              workAddressStateProvince="Shanghai"
              workAddressCountryRegion="China"
              vcardSource="https://biznesscard.net/"
              contactLocation2="Accra, Ghana"
              contactPost="Certified Project Manager PMI-ACP | Founder, Altreon Technologies | CEO, TheBlock."
              contactOrganization1="Altreon Technologies"
              contactOrganization2="TheBlock App"
              companyWebsite="https://theblockapp.co/"
              contactFacebook="https://web.facebook.com/profile.php?id=100092529806652"
              contactInstagram="https://instagram.com/altreontech?igshid=NTc4MTIwNjQ2YQ=="
              contactGithub="https://github.com/elyxer"
              contactLinkedIn="https://www.linkedin.com/in/elias-lloyd-yemoh/"
            />
          </div>

          <PortfolioBody works={works} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePortfolio;
