const ShareContact = () => {
  return (
    <div className="w-full bg-white flex flex-row flex-wrap p-[50px] box-border items-center justify-center gap-[50px] max-w-[1440px] text-left text-lg text-darkslategray-100 font-inter sm:gap-[10px] sm:pl-5 sm:pr-5 sm:box-border">
      <div className="flex-1 flex flex-col items-start justify-start gap-[29px] min-w-[400px] max-w-[560px] sm:min-w-[360px]">
        <div className="self-stretch flex flex-col items-start justify-start text-17xl text-darkslategray-200 font-sansation">
          <div className="self-stretch relative">
            Share contact information with confidence and convenience
          </div>
          <div className="relative text-lg font-inter text-orange-200">
            {" "}
            Save Time
          </div>
        </div>
        <div className="self-stretch relative inline-block min-w-[400px] sm:text-left sm:min-w-[360px]">
          Share your contact within 3 seconds and further intrigue prospective
          business partners and clients. Know immediately that your contact has
          been saves and wont be lost along with the a card
        </div>
        <div className="self-stretch relative h-[70px]">
          <div className="absolute top-[0px] left-[0px] w-[172px] h-[25px]">
            <div className="absolute top-[1px] left-[51px]">{`QR code Scan `}</div>
            <img
              className="absolute top-[0px] left-[0px] w-[25px] h-[25px]"
              alt=""
              src="/icon3.svg"
            />
          </div>
          <div className="absolute top-[45px] left-[0px] w-[136px] h-[25px]">
            <div className="absolute top-[1px] left-[51px]">BNS Scan</div>
            <img
              className="absolute top-[0px] left-[0px] w-[25px] h-[25px]"
              alt=""
              src="/icon3.svg"
            />
          </div>
        </div>
      </div>
      <img
        className="relative w-[416px] h-[414px] object-cover"
        alt=""
        src="/image@2x.png"
      />
    </div>
  );
};

export default ShareContact;
