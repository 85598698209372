import TopWorkNav from "../../components/TopWorkNav";
import ContactCard from "../../components/ContactCard";
import PortfolioBody from "../../components/PortfolioBody";

const ProfilePortfolio = () => {
  const works = [
    {
      img: "/BENE/SRM.jpeg",
      category: "Ministry",
      projectName: "Spiritlife Revival Ministries",
      link: "#/beneshunwork",
    },

    {
      img: "/BENE/xv.jpeg",
      category: "Consulting",
      projectName: "XViral",
      link: "#/beneshunwork",
    },
    {
      img: "/BENE/fridayproph.jpeg",
      category: "Ministry",
      projectName: "Strictly Prophetic",
      link: "#/beneshunwork",
    },
    {
      img: "/BENE/mof.jpeg",
      category: "Ministry",
      projectName: "Madness Of Faith Convention",
      link: "#/beneshunwork",
    },
  ];

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#fbaa0c_35%)] w-full overflow-hidden flex flex-col py-10 px-2.5 box-border items-center justify-start text-left text-17xl text-black font-sacramento lg:pl-2.5 lg:pr-2.5 lg:box-border md:w-[960px] md:items-center md:justify-start sm:pr-0 sm:box-border sm:max-w-full sm:[background:linear-gradient(180deg,_#f2f5f9_7%,_#fbaa0c_15%)]">
      <div className="w-full flex flex-col items-center justify-start gap-[186px] max-w-[1440px] sm:pl-0 sm:pr-[5px] sm:box-border">
        <TopWorkNav name="Prophet Elbernard" homepage="beneshun" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[0px]">
            <ContactCard
              contactID="beneshun"
              title="Prophet"
              profileImage="/BENE/ben4234.jpeg"
              contactFName="Bernard"
              contactMName="Elbernard"
              contactLName="Nelson-Eshun"
              contactPhone1="+233550000096"
              contactPhone2="+233234077788"
              contactEmail1="prophetbernardwlbernard@gmail.com"
              contactEmail2=""
              workAddressStreet=""
              workAddressCity=""
              workAddressStateProvince="Greater Accra"
              workAddressCountryRegion="Ghana"
              vcardSource=""
              contactLocation2="Accra, Ghana"
              contactPost="General Overseer @ SPIRITLIFE REVIVAL MINISTRIES | Prophet | Forex Trader | Cryptopreneur | Moneypreneur"
              contactOrganization1="SPIRITLIFE REVIVAL MINISTRIES"
              contactOrganization2="TheBlock App"
              companyWebsite=""
              contactFacebook="https://web.facebook.com/bernard.nelsontv"
              contactInstagram="https://www.instagram.com/prophetbernardelbernard/"
              contactGithub=""
              contactLinkedIn="https://www.linkedin.com/in/bernard-elbernard-nelson-eshun-578723226/"
              contactTwitter="https://twitter.com/ProphElbernard"
              contactTelegram="https://t.co/5JZ1TODt6J"
              contactThreads="https://www.threads.net/@prophetbernardelbernard"
            />
          </div>

          <PortfolioBody works={works} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePortfolio;
