import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const LandingPageFooter = () => {
  const navigate = useNavigate();

  const onDevelopTextClick = useCallback(() => {
    navigate("/finalprofilehome");
  }, [navigate]);

  return (
    <div className="self-stretch w-full bg-black flex flex-col py-[50px] px-0 items-center justify-center gap-[18px] text-left text-base text-white font-inter sm:w-[420px]">
      <div className="self-stretch flex flex-row flex-wrap py-0 px-[150px] items-center justify-center gap-[110px]">
        <div className="relative w-[102px] h-[59px]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/image-3@2x.png"
          />
        </div>
        <div className="flex-1 flex flex-col items-center justify-start min-w-[240px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <a className="[text-decoration:none] relative text-[inherit]">
              Design
            </a>
            <div
              className="relative cursor-pointer"
              onClick={onDevelopTextClick}
            >
              Develop
            </div>
            <a className="[text-decoration:none] relative text-[inherit]">
              Encode
            </a>
            <a className="[text-decoration:none] relative text-[inherit]">
              Print
            </a>
          </div>
        </div>
        <img
          className="relative w-[47px] h-[12.02px]"
          alt=""
          src="/socialmedia-icon.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[18px] text-center text-xs text-dimgray">
        <div className="relative box-border w-[978px] h-px border-t-[1px] border-solid border-gray-200 sm:w-[420px]" />
        <div className="relative font-medium flex items-center justify-center w-[184px]">
          Copyright © 2023 Reserved
        </div>
      </div>
    </div>
  );
};

export default LandingPageFooter;
