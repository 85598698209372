import OnePagerNav from "../../components/OnePagerNav";
import ContactCard from "../../components/ContactCard";
import MainHomeBody from "../../components/MainHomeBody";

const ProfileHome = () => {
  const description = `Experienced business strategist with a proven track record of driving growth and maximizing profitability for diverse organizations. Adept at market analysis, strategic planning, and innovative problem-solving. Skilled in developing and executing business models to adapt to evolving industry trends. Demonstrated leadership and collaboration in cross-functional teams. Passionate about optimizing operations and fostering sustainable success in dynamic business environments.`;

  const interests = [
    {
      icon: "/hands-praying.svg",
      title: "GROWTH, SALES & MARKETING",
      desc: "I provide cutting-edge insight into consumer behavior, brand perception, and innovative opportunities. I make data-driven decisions which facilitate effective marketing to dominate the industry.",
    },

    {
      icon: "/bitcoin.svg",
      title: "OPERATIONS",
      desc: "I adopt technological and modern-day practices fused with vast experience to build a connection between your organization's strategy and its success.",
    },
    {
      icon: "/forexpound.svg",
      title: "PEOPLE & ORGANIZATIONAL MANAGEMENT",
      desc: "I strive to help you create a state-of-the-art organization and develop a strategy which not only meets today’s needs but also, sustain performance in the future. I provide data-driven insight to empower and inspire your workforce to create a chain of valuable results",
    },
    {
      icon: "/mentor-icon.svg",
      title: "DIGITAL",
      desc: "I help clients pinpoint emerging opportunities, find unexpected value, and create new businesses by harnessing the full power of media. I provide clients with proprietary, sector-specific research and insights on what drives value in the digital economy.",
    },
  ];

  return (
    <div className="relative [background:linear-gradient(180deg,_#f2f5f9_35%,_#0f05a5_35%)] w-full overflow-hidden flex flex-row flex-wrap py-10 px-2.5 box-border items-start justify-center lg:max-w-[1200px] md:items-center md:justify-start md:pl-2.5 md:box-border md:max-w-[960px] sm:pl-[15px] sm:pr-2.5 sm:box-border sm:max-w-[410px] sm:[background:linear-gradient(180deg,_#f2f5f9_7%,_#0f05a5_15%)]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[186px] max-w-[1440px] lg:w-full sm:flex-col sm:gap-[136px] sm:pl-0 sm:box-border">
        <OnePagerNav name="Felix Armah" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[41px] sm:flex-row sm:gap-[20px] sm:items-center sm:justify-start sm:pl-0 sm:pr-0 sm:box-border">
          <div className="flex-1 rounded-mini bg-white shadow-[0px_0px_1px_rgba(0,_0,_0,_0)] flex flex-col items-center justify-center min-w-[300px] max-w-[400px] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border sm:mt-[50px]">
            <ContactCard
              contactID="felix_armah"
              title="Mr."
              profileImage="/FELIX/feel.jpg"
              contactFName="Felix"
              contactMName=""
              contactLName="Armah"
              contactPhone1="+233503636029"
              contactPhone2=""
              contactEmail1="felixniiayiarmah@gmail.com"
              contactEmail2=""
              workAddressStreet=""
              workAddressCity=""
              workAddressStateProvince="Accra, Ghana"
              workAddressCountryRegion=""
              vcardSource=""
              contactLocation2="Abuja, Nigeria"
              contactPost="Business Strategist | Entrepreneur. Managing Partner, STRAEL."
              contactOrganization1="STRAEL"
              contactOrganization2=""
              companyWebsite=""
              contactFacebook=""
              contactInstagram="https://instagram.com/felwade?igshid=NzZlODBkYWE4Ng=="
              contactGithub=""
              contactLinkedIn=""
              contactTwitter=""
              contactTelegram=""
              contactThreads=""
            />
          </div>

          <MainHomeBody description={description} interests={interests} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
